export default function stringifyProps(props) {
  if (props) {
    const string = Object.entries(props)
      .filter(([, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}="${value}"`)
      .join(' ');

    return string ? ` ${string}` : '';
  }

  return '';
}
