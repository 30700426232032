/**
 * @param {object} block
 * @param {string} variant
 * @returns {string}
 */
export default function button(block, variant = 'primary') {
  const defaultTextButton = 'Button Text';
  const { href, targetBlank, noFollow, textButton } = block.data;

  const rel = noFollow ? 'nofollow' : 'noreferrer';
  const target = targetBlank ? '_blank' : '_self';

  const basisStyles =
    'border-radius: 4px;display: inline-block;line-height: 1em; padding: 0.7em 1.2em;text-decoration:none;';
  const variantStyles = {
    primary: 'background: #001fe6; color: #ffffff;',
  };

  const className = `strchf-button strchf-button--${variant}`;

  const style = `${basisStyles} ${variantStyles[variant]}`;
  const anchorProps = `href="${href}" rel="${rel}" target="${target}"`;
  const domElement = href
    ? `<a class="${className}" ${anchorProps} style="${style}">${
        textButton || defaultTextButton
      }</a>`
    : `<span class="${className}" style="${style}">${textButton || defaultTextButton}</span>`;

  return `<p class="strchf-button-wrapper">${domElement}</p>`;
}
