export default function getAnchorProps(href, isBlank, isNoFollow) {
  const props = {
    href,
  };

  if (isBlank) {
    props.target = '_blank';
    props.rel = 'noopener noreferrer';
  }

  if (isNoFollow) {
    props.rel = 'nofollow';
  }

  return props;
}
